$headerBackground: #fff;
$layoutBackground: #fff;
$siderBackground: #fff;
$darkGreen: #12b883;
$black: #111313;
$primary: #0edd9b;

:export {
  headerBackground: $headerBackground;
  layoutBackground: $layoutBackground;
  siderBackground: $siderBackground;
  primary: $primary;
  black: $black;
  darkGreen: $darkGreen;
}
