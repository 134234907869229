.container {
  margin: 40px;

  h3 {
    margin-top: 0 !important;
    margin-bottom: 32px !important;
  }

  tr > td:first-child,
  tr > td:nth-child(2) {
    border-top: 1px solid #f0f0f0;
  }
}

.top {
  display: flex;
  gap: 16px;
  align-items: baseline;

  span {
    cursor: pointer;
  }
}
