@import '../../assets/scss/variables.scss';

.spin {
  //    min-width: 14px;
  width: 100%;
  align-items: center;
}
.loading {
  color: $primary;
}
