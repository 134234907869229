@import '../../assets/scss/variables.scss';

html {
  background-color: $headerBackground;
}

// кастомный скролл
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $darkGreen;
}

#root {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

h1 {
  margin: 0;
}

.ant-menu-light.ant-menu-inline .ant-menu-item-selected::after,
.ant-menu-light.ant-menu-inline .ant-menu-item-selected::after {
  border-right-width: 2px;
  border-right-color: $primary;
}

.ant-menu .ant-menu-item,
.ant-menu .ant-menu-item {
  border-radius: 0;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  margin-inline: 0;
  width: 100%;
}

.ant-segmented.ant-segmented-lg .ant-segmented-item-label,
.ant-segmented.ant-segmented-lg .ant-segmented-item-label {
  line-height: normal;
  padding: 7px 24px;
}

.ant-card-bordered {
  border-radius: 8px;
}

.ant-select-selector {
  border-radius: 20px;
}

:where(.css-dev-only-do-not-override-vvzjsd).ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 6px;
}

.ant-input,
.ant-input-affix-wrapper {
  border-radius: 6px;
}

.ant-input-number,
.ant-input-number-affix-wrapper {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.ant-input-number-group .ant-input-number-group-addon {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ant-input-group .ant-input-group-addon {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.ant-modal .ant-modal-content {
  border-radius: 8px;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  border-color: rgba(0, 0, 0, 0.25);
  background-color: white;

  > .ant-steps-icon {
    color: rgba(0, 0, 0, 0.25);
  }
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  border-color: $primary;
  background-color: white;
}

.ant-tag-green {
  border: 1px solid rgba(18, 184, 131, 0.4);
  background: #f1fbf8;
  color: $darkGreen;
}

.ant-tag-default {
  border: 1px solid #a8a8a8;
  background: rgba(168, 168, 168, 0.12);
  color: #a8a8a8;
}

.ant-dropdown-trigger {
  color: rgba(0, 0, 0, 0.85);
  &:hover {
    color: rgba(0, 0, 0, 0.6);
  }
}

// специфичные стили к конкретному элементу, а не группе элементов
#buy_paymentMethod,
#sell_paymentMethod {
  .ant-segmented-item-selected,
  .ant-segmented-thumb,
  .ant-segmented-item:active::after {
    background-color: white !important;
    color: #262626;
  }

  .ant-segmented-item {
    transition: white 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}
