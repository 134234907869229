.container {
  margin: 40px;

  h3 {
    margin: 0;
    margin-bottom: 32px !important;
  }

  h4 {
    margin-top: 0 !important;
    margin-bottom: 24px !important;
  }

  .descriptions {
    th {
      padding-bottom: 8px !important;
    }

    td {
      padding-bottom: 24px !important;
    }

    tr:last-child td {
      padding-bottom: 0 !important;
    }
  }

  div[role='separator'] {
    margin: 40px 0;
  }
}

.statistic {
  display: flex;
  gap: 40px;
  width: 100%;

  tr > td:first-child,
  tr > td:nth-child(2) {
    border-top: 1px solid #f0f0f0;
  }

  > div {
    width: inherit;

    > div {
      height: 100%;

      > h5 {
        margin-top: 2px;
        margin-bottom: 20px;
      }
    }
  }

  .empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.verification {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 40px;

  > .btn {
    margin-top: 24px;
  }

  > .description {
    margin-top: 8px;
  }
}
